<template>
  <div>
    <Header ref="header" @scrollIntoView="scrollIntoView" />
    <SuspensionConsult />
    <div class="welcome_wrap" ref="home">
      <div class="welcome_cont">
        <p class="text2">助力中国制造企业</p>
        <p class="text3">逐步升级</p>
        <p class="text1">专属的云边轻应用市场，一键安装部署</p>
        <button class="pointer registered_btn" @click="$bus.$emit('showApply')">
          申请演示
        </button>
      </div>
      <img class="welcome_wrap_bg" src="@/assets/welcome_wrap_bg.png" alt="" />
    </div>
    <div class="productServe_wrap" ref="productServe">
      <p class="productServe_title">产品服务</p>
      <p class="productServe_des">提效节能控制成本个性化服务</p>
      <div class="product_cont">
        <div class="prodct_item" @click.stop="changeProductServe('trace')">
          <img class="item_img" src="@/assets/zhuisu_icon.png" alt="" />
          <p class="item_name">
            <span class="name_text">追溯应用</span>
          </p>
        </div>
        <div class="prodct_item" @click.stop="changeProductServe('spc')">
          <img class="item_img" src="@/assets/SPC_icon.png" alt="" />
          <p class="item_name">
            <span class="name_text">SPC</span>
          </p>
        </div>
        <div class="prodct_item" @click.stop="changeProductServe('workorder')">
          <img class="item_img" src="@/assets/gongdan_icon.png" alt="" />
          <p class="item_name">
            <span class="name_text">工单管理</span>
          </p>
        </div>
        <div class="prodct_item" @click.stop="changeProductServe('warning')">
          <img class="item_img" src="@/assets/shebeiyujing_icon.png" alt="" />
          <p class="item_name">
            <span class="name_text">设备预警</span>
          </p>
        </div>
        <div class="prodct_item" @click.stop="changeProductServe('print')">
          <img class="item_img" src="@/assets/yundayin_icon.png" alt="" />
          <p class="item_name">
            <span class="name_text">云打印</span>
          </p>
        </div>
        <div class="prodct_item" @click.stop="changeProductServe('andon')">
          <img class="item_img" src="@/assets/andeng_icon.png" alt="" />
          <p class="item_name">
            <span class="name_text">安灯应用</span>
          </p>
        </div>
      </div>
    </div>
    <div class="serve_wrap" v-if="productServeType === 'trace'">
      <img class="serve_bg" src="@/assets/serve_bg.png" alt="" />
      <div class="serve_cont">
        <p class="serveText">
          1、融合多端集成管理，数据互通，实时同步，满足更多应用场景
        </p>
        <p class="serveText">
          2、C2M个性化定制：以客户为中心，在微订货商城进行个性化选配，并监督生产和物流过程
        </p>
        <p class="serveText">
          3、车间现场管理：掌上工厂MES全面管理现场流程，实时采集生产数据，建立工位制生产管理平台。
        </p>
        <p class="serveText">
          4、可视化生产：电子看板实时监控生产执行情况，图形化展示，公开透明，轻松掌握车间动态。
        </p>
      </div>
    </div>
    <div class="serve_wrap" v-if="productServeType === 'spc'">
      <img class="serve_bg" src="@/assets/spc.png" alt="" />
      <div class="serve_cont">
        <p class="serveText">
          1、针对加工过程每个工序进行质量控制，潜在问题及时发现，有效减少生产过程中的损失
        </p>
        <p class="serveText">2、各种可视化图表，一目了然，让生产质量清晰可见</p>
        <p class="serveText">3、数据采集实时同步，安全性高</p>
        <p class="serveText">4、多端管理，云端、掌上也可实时查看</p>
      </div>
    </div>
    <div class="serve_wrap" v-if="productServeType === 'workorder'">
      <img class="serve_bg" src="@/assets/workorder.png" alt="" />
      <div class="serve_cont">
        <p class="serveText">
          1、灵活易用，多端轻松管理多批次工单，并可以与企业ERP系统对接，方便企业各部门之间工单管理
        </p>
        <p class="serveText">
          2、生产透明化，生产进度与过程质量一览无遗，工单计划、产量、良品率等数据实时监控
        </p>
        <p class="serveText">
          3、对不同工单进行记录，分类，追踪，统计，分析，帮助企业实现序列化管理
        </p>
        <p class="serveText">
          4、提供工单查询入口，可快速追溯当前工单、工件信息
        </p>
      </div>
    </div>
    <div class="serve_wrap" v-if="productServeType === 'warning'">
      <img class="serve_bg" src="@/assets/device_monitor.jpeg" alt="" />
      <div class="serve_cont">
        <p class="serveText">1、通用性高，兼容各种生产设备以及工控机</p>
        <p class="serveText">
          2、提前预警，防范于未然。对生产设备参数实时检测并与与测试数据进行分析，对设备状态变化趋势判断，潜在故障隐患提前预警,实时性高
        </p>
        <p class="serveText">
          3、故障预警推送放方式多样化。故障预警在线监测诊断，并通过邮件、广播、微信推送等方式预警
        </p>
        <p class="serveText">
          4、全面直观的运行可视化。提供实时的可视化展示，管理、维护人员能实时掌控设备运行状态
        </p>
      </div>
    </div>
    <div class="serve_wrap" v-if="productServeType === 'print'">
      <img class="serve_bg" src="@/assets/print.jpeg" alt="" />
      <div class="serve_cont">
        <p class="serveText">
          1、打印标签云端统一管理，云端管理、编辑标签，防止丢失
        </p>
        <p class="serveText">
          2、打印超越局域网、地域限制，随时随地实现跨网络、多终端异地打印
        </p>
        <p class="serveText">3、跨系统打印，不受操作系统限制</p>
      </div>
    </div>
    <div class="serve_wrap" v-if="productServeType === 'andon'">
      <img class="serve_bg" src="@/assets/andon.jpeg" alt="" />
      <div class="serve_cont">
        <p class="serveText">1、设备故障、运行状态、维护信息等实时管理呼叫</p>
        <p class="serveText">
          2、产线Andon信息可视化看板，实时显示呼叫信息、故障信息、停线信息等
        </p>
        <p class="serveText">
          3、多种实时通知方式。当设备故障报警，通过邮件、微信等多种方式实时推送报警信息给维护人员
        </p>
      </div>
    </div>
    <div class="platform_wrap" ref="platform">
      <p class="platform_title">葫芦工业云</p>
      <p class="platform_subtitle">
        专属云边轻应用市场，云、边、端三端协同
      </p>
      <div class="platform_cont">
        <div class="cont_left">
          <p class="cont_title">葫芦工业云简介</p>
          <p class="cont_des">
            葫芦工业云是一个集云边市场、应用于一体的工业化平台，打通了云、边、端三层相互协同。
          </p>
          <p class="cont_des">
            云平台服务，提供云边应用市场，专注于工业领域的云服务系统框架，葫芦云上传的设备数据，具备高稳定性、高安全性、支持高并发量的特点。
          </p>
          <p class="cont_des">
            边缘自治，边缘端自成系统，弱网或断网与云端连接中断时不影响边缘端业务运行，边缘端重启时无需从云端获取数据。
          </p>
          <p class="cont_des">
            终端设备，百万级集群规模能力，可以自定义设备模型并通过设备孪生技术实现与物理设备解耦，屏蔽底层接入协议。
          </p>
          <div class="advantage">
            <p class="advantage_title">葫芦工业云优势</p>
            <div class="advantage_cont">
              <div class="advantage_item">
                <img
                  class="advantage_icon"
                  src="@/assets/advantage_icon.png"
                  alt=""
                />
                <p class="advantage_text">
                  专属的工业应用市场，汇聚各种工业应用，满足企业生产需要
                </p>
              </div>
              <div class="advantage_item">
                <img
                  class="advantage_icon"
                  src="@/assets/advantage_icon.png"
                  alt=""
                />
                <p class="advantage_text">
                  一键式部署，方便、快捷，大大节省部署时间与费用
                </p>
              </div>
              <div class="advantage_item">
                <img
                  class="advantage_icon"
                  src="@/assets/advantage_icon.png"
                  alt=""
                />
                <p class="advantage_text">多端支持，随时随地查看企业情况</p>
              </div>
              <div class="advantage_item">
                <img
                  class="advantage_icon"
                  src="@/assets/advantage_icon.png"
                  alt=""
                />
                <p class="advantage_text">
                  数据安全性高，专属的云服务器，安全物理隔离
                </p>
              </div>
              <div class="advantage_item">
                <img
                    class="advantage_icon"
                    src="@/assets/advantage_icon.png"
                    alt=""
                />
                <p class="advantage_text">
                  集中监控，统一运维
                </p>
              </div>
            </div>
          </div>
          <button class="pointer seeMore_btn">查看更多</button>
        </div>
        <img class="platform_bg" src="@/assets/platform_bg.png" alt="" />
      </div>
    </div>
    <div class="customer_wrap" ref="customer">
      <p class="customer_title">客户案例</p>
      <p class="customer_des">为多家企业制定专属解决方案</p>
      <div class="customer_cont">
        <div class="customer_item">
          <img class="item_img" src="@/assets/customer_img1.png" alt="" />
          <p class="item_name">上汽大众</p>
          <p class="item_subtitle">世界500强汽车生产公司</p>
          <p class="item_des">
            为达成碳中和的终极目标，大众汽车已有了具象的动作和达成节点。
            要知道，过去五年时间里，特斯拉通过出售碳排放额度已获得33亿美元的收入，而这项收入仅在2020年就占据其收入总额的一半。
          </p>
        </div>
        <div class="customer_item">
          <img class="item_img" src="@/assets/customer_img2.png" alt="" />
          <p class="item_name">德国采埃孚（ZF）集团</p>
          <p class="item_subtitle">世界前10零部件制造商</p>
          <p class="item_des">
            世界财富五百强企业，天合生产制动、转向、悬挂、乘员安全方面的高科技主、被动安全产品及系统并提供售后市场作业。
          </p>
        </div>
        <div class="customer_item">
          <img class="item_img" src="@/assets/customer_img3.png" alt="" />
          <p class="item_name">浙江航驱汽车(eCDAG)</p>
          <p class="item_subtitle"></p>
          <p class="item_des">
            浙江航驱汽车科技有限公司位于中国杭州，是一家集研发、生产、销售为一体的科技创新型企业，致力于为中国汽车市场提供先进完整的底盘电控产品和智能驾驶相关核心技术，主要产品包含无刷电动助力转向（EPS）、控制器集成式电动助力单元、齿条式无刷电动转向系统（SP-EPS、DP-EPS、RD-EPS）以及线控转向系统（SBW）、智能电控制动产品（AEBS）。
          </p>
        </div>
        <div class="customer_item">
          <img class="item_img" src="@/assets/customer_img4.png" alt="" />
          <p class="item_name">安徽通宇电子</p>
          <p class="item_subtitle"></p>
          <p class="item_des">
            为国内多家汽车主机厂配套。公司始终坚持自主创新的思路。与合工大产学研联合建立汽车控制网络及系统工程技术研究中心。公司研发主力来自原兵器部第214研究所的技术骨干。
          </p>
        </div>
      </div>
    </div>
    <div class="dynamic_wrap" ref="dynamic">
      <p class="dynamic_title">动态新闻</p>
      <p class="dynamic_des">最新动态 一手掌握</p>
      <div class="dynamic_cont">
        <template v-for="(item, index) in newsList">
          <div
            class="dynamic_item"
            :key="index"
            @click.stop="seeNewsDetail(item)"
          >
            <img class="item_img" :src="item.image" alt="" />
            <div class="item_cont">
              <p class="item_name">
                {{ item.title }}
              </p>
              <p class="item_des">
                {{ item.remark }}
              </p>
            </div>
            <div class="time_see">
              <span class="time">{{ item.created_at }}</span>
              <span class="seeDetail">查看详情</span>
            </div>
          </div>
        </template>
      </div>
      <!-- <button class="pointer seeMore_btn">查看更多</button> -->
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import SuspensionConsult from "@/components/suspensionConsult";
import { newsListApi } from "@/api";
export default {
  components: {
    Header,
    Footer,
    SuspensionConsult,
  },
  data() {
    return {
      scrollTop: 0,

      productServeType: "trace",
      newsList: [], //动态新闻
    };
  },
  computed: {},
  mounted() {
    this.getNewsList();
    this.$nextTick(() => {
      this.handleScroll();
      window.addEventListener("scroll", this.handleScroll);
      if (this.$route.query.pageViewType) {
        this.scrollIntoView(this.$route.query.pageViewType);
      }
    });
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    changeProductServe(type) {
      this.productServeType = type;
    },
    async getNewsList() {
      const res = await newsListApi({ num: 3 });
      if (res.code == 0) {
        this.newsList = res.data.items;
      }
    },
    seeNewsDetail(e) {
      this.$router.push({
        path: "/dynamicDetail",
        query: {
          newsId: e.id,
        },
      });
    },
    handleScroll() {
      let flag;
      if (this.$func.isElementInViewport(this.$refs["home"])) {
        flag = "home";
      } else if (this.$func.isElementInViewport(this.$refs["productServe"])) {
        flag = "productServe";
      } else if (this.$func.isElementInViewport(this.$refs["platform"])) {
        flag = "platform";
      } else if (this.$func.isElementInViewport(this.$refs["customer"])) {
        flag = "customer";
      } else if (this.$func.isElementInViewport(this.$refs["dynamic"])) {
        flag = "dynamic";
      } else {
        flag = "home";
      }
      this.$refs["header"].nav_active = flag;
    },
    scrollIntoView(e) {
      this.anchorTo(e);
    },
    anchorTo(id) {
      let element = this.$refs[id];
      element.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss">
html,
body {
}
</style>
<style lang='scss' scoped>
@import "./index.scss";
</style>
